import React from 'react';
import './Footer.css';

function Footer() {
    return (
      <div className = "Footer">
          <h3>Contact Us</h3>
          <p>4131 Woodland Park Ave N, Seattle, WA 98103</p>
          <p>mia@opensky.studio</p>
          <p>(206) 399-5647</p>

      </div>
    );
  }

  export default Footer;